import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import RolexCategoryBtns from '../../components/preowned/RolexCategoryBtns'

// markup
const RolexPearlmaster = () => {
  const data = useStaticQuery(
    graphql`
      query queryRolexPearlmaster {
        products: allStrapiProduct(
          filter: { brand: { eq: "Rolex" }, model: { regex: "/Pearlmaster/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Rolex Pearlmaster Watches for Sale'}
      canonical={'/fine-watches/rolex/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Rolex Pearlmaster Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/catalogsearch/result?q=pearlmaster">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-rolex-pearlmaster-header.png"
                alt="Pre-Owned Certified Used Rolex Pearlmaster Watches Header"
                aria-label="Used Rolex Pearlmaster Watch Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">
              ROLEX PEARLMASTER: PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED ROLEX WATCHES
            </h1>
            <h2>BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED ROLEX PEARLMASTER WATCHES</h2>
            <p>
              <br />
              <b>Pre-Owned Used Rolex Pearlmaster</b>
              <br />
              <br />
              One of the brand’s most luxurious collections, the Rolex Pearlmaster is a line of
              jewelry watches for women, exclusively fashioned from solid gold or solid platinum
              and, more often than not, embellished with precious diamonds. While the time and date
              dials of Rolex Pearlmaster watches are marked with the famous Datejust name, these
              ultra-special ladies’ timepieces are named after their unique bracelets. The
              Pearlmaster bracelet has a five-piece configuration with rounded links that glisten
              thanks to high-polish finishing. Due to their exclusivity and price, used Rolex
              Pearlmaster watches are quite rare in the secondary market.
              <br />
              <br />
              <b>Buying and Selling Pre-Owned Used Rolex Pearlmaster Watches</b>
              <br />
              <br />
              The secondary market for luxury watches offers a much wider variety of Pearlmaster
              watches than the current in-production collection. This is simply because Rolex has
              discontinued several references. For example, pre-owned Rolex Pearlmaster watches are
              available in three sizes: 29mm, 34mm, and 39mm. What’s more, there are secondhand
              Rolex Pearlmaster watches in yellow gold, white gold, Everose pink gold, and platinum.
              Finally, when looking at used Rolex Pearlmaster watches, you can choose various
              diamond options, from a single stone to a few diamonds to versions that have cases,
              dials, and bracelets that are entirely set with diamonds.
              <br />
              <br />
              Popular Pre-Owned Used Rolex Pearlmaster Watch References:
              <br />
              <ul>
                <li>Pearlmaster ref. 69298</li>
                <li>Pearlmaster ref. 69318</li>
                <li>Pearlmaster ref. 80299</li>
                <li>Pearlmaster ref. 80298</li>
                <li>Pearlmaster ref. 80318</li>
                <li>Pearlmaster ref. 80319</li>
                <li>Pearlmaster ref. 80339</li>
                <li>Pearlmaster ref. 81315</li>
                <li>Pearlmaster ref. 81285</li>
              </ul>
              <br />
              <br />
              As an independent Rolex dealer, Gray and Sons boasts both inventory control and price
              flexibility. Our
              <a href="/chat-with-rich">decision-makers </a> are ready to make deals on our
              well-stocked used Rolex watch inventory, which includes used Pearlmaster watches. Any
              preowned Rolex Pearlmaster for sale on our website is in stock, and ready to ship
              immediately. Otherwise, if you’ve thought to yourself, “where can I sell my used Rolex
              Pearlmaster watch?” get the best cash offer by visiting{' '}
              <a href="https://sellusyourjewelry.com">www.sellusyourjewelry.com</a>
              <br />
              <br />
              <b>
                Gray and Sons is the Best Source for Buying and Selling Pre-Owned Rolex Pearlmaster
                Watches
              </b>
              <br />
              <br />
              At Gray and Sons, we’ve had the pleasure of serving thousands of happy customers for
              more than 40 years. And we’re proud that many of those clients have named Gray and
              Sons the #1 buyer and seller of pre-owned used Rolex Pearlmaster watches. In addition
              to our leading e-commerce pre-owned luxury watch buying and selling website,
              <a href="/">Grayandsons.com</a> is also a physical store, located near the prestigious
              Bal Harbour Shops in the Surfside/Miami area. The Gray and Sons boutique is open six
              days a week; we welcome you to browse our large selection of secondhand Rolex
              Pearlmaster watches in-store. Our on-site watch experts are ready to help you buy or
              sell pre-owned Rolex Pearlmaster watches. We are even home to an independent Rolex
              Pearlmaster watch repair facility for all your maintenance needs.
              <br />
              <br />
              Gray and Sons serves the Miami, Miami Beach, Coral Gables, Coconut Grove, South Beach,
              Bal Harbour, Surfside, Sunny Isles, Ventura, Fort Lauderdale, Pinecrest, and Brickle
              areas.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/rolex-cellini/">
                <button>SHOP ROLEX PEARLMASTER WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK ROLEX PEARLMASTER WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <section>
        <h2 className="text-center py-10">EXPLORE MORE GREAT WATCH STORIES FROM GRAY &amp; SONS</h2>
        <RolexCategoryBtns />
      </section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default RolexPearlmaster
